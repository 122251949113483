import React from 'react';
import { useViewport } from '@src/contexts/Viewport';

// components
import LinkButton from '@src/components/button/LinkButton';
import Loader from '@src/components/loader/Loader';
import RippleCanvas from '@src/components/rippleCanvas/RippleCanvas';

export default function TextImage(props) {
    const { isMobile } = useViewport();
    const { content } = props;

    return (
        <div className="grid grid--2 grid--reverse spacing">
            <div className={`flex wrap align-center secondary ${isMobile ? 'justify-center' : ''}`}>
                <div
                    className="rich-text"
                    dangerouslySetInnerHTML={{ __html: content?.copyBlock }}
                />
                {content?.blockEntry[0] ? (
                    <LinkButton to={`${content.blockEntry[0].url}`} copy="Buy now" large />
                ) : null}
            </div>
            <div>
                {content?.rippleEntry[0]?.rippleAsset[0] ? (
                    <RippleCanvas product short asset={content.rippleEntry[0].rippleAsset[0]} />
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
}
